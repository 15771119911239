<template>
  <b-modal
    id="reiseterminVerschiebenModal"
    :title="`Termin ${reiseterminkuerzelFormatted} verschieben`"
    @hide="onHide"
  >
    <b-overlay :show="isLoading">
      <b-form-group label="Neues Abreisedatum">
        <DatePicker
          :lang="langConfig"
          type="date"
          format="DD.MM.YYYY"
          v-model="date"
          :default-value="startdatum"
        ></DatePicker>
      </b-form-group>
    </b-overlay>
    <template #modal-footer="{ hide }">
      <b-button
        class="mr-3"
        @click="handleSubmit(hide)"
        :disabled="isSichernButtonDisabled"
        :variant="isSichernButtonDisabled ? '' : 'success'"
      >
        Erstellen
      </b-button>
      <b-button @click="hide">Abbrechen</b-button>
    </template>
  </b-modal>
</template>

<script>
import { langConfig } from '@/core/common/helpers/utils.js';
import DatePicker from 'vue2-datepicker';
import { format, parse } from 'date-fns';
import apiService from '@/core/common/services/api.service';

export default {
  props: {
    reiseterminkuerzel: { type: String, required: true },
    reiseterminId: { type: Number, required: true },
  },
  components: { DatePicker },
  data() {
    return {
      date: null,
      langConfig,
      isLoading: false,
    };
  },
  watch: {
    date(newDate) {
      if (newDate) {
        this.isLoading = true;
        const abreisedatum = format(newDate, 'yyyy-MM-dd');

        apiService
          .get(
            `Reisetermin?$filter=abreisedatum eq ${abreisedatum} and reise/reisekuerzel eq '${this.reiseterminkuerzel.substring(
              0,
              6
            )}'&$expand=reise`
          )
          .then(res => {
            const { length } = res.data.result?.value;
            if (length != 0) {
              this.date = null;
              this.toast('Reisetermin existiert bereits.', { variant: 'danger' });
            }
          })
          .finally(() => (this.isLoading = false));
      }
    },
  },
  computed: {
    isSichernButtonDisabled() {
      return this.date == null || this.isLoading;
    },
    startdatum() {
      return parse(this.reiseterminkuerzel?.substring(7), 'yyyy-MM-dd', new Date());
    },
    reiseterminkuerzelFormatted() {
      const reisekuerzel = this.reiseterminkuerzel.substring(0, 6);
      const startdatum = format(
        parse(this.reiseterminkuerzel.substring(7), 'yyyy-MM-dd', new Date()),
        'dd.MM.yyyy'
      );

      return reisekuerzel + ' ' + startdatum;
    },
  },
  methods: {
    handleSubmit(hide) {
      apiService
        .post('Reisetermin/terminVerschieben', {
          ursprungsterminId: this.reiseterminId,
          neuerTerminDate: format(this.date, 'yyyy-MM-dd'),
        })
        .then(() => {
          this.toast('Bitte denke daran, terminspezifische Daten in die Termine neu einzupflegen.', {
            variant: 'warning',
            noCloseButton: false,
            noAutoHide: true,
            title: 'Hinweis!',
          });

          this.$emit('terminVerschoben');
        });
      hide();
    },
    onHide() {
      this.date = null;
    },
  },
};
</script>

<style scoped>
:deep(.modal-footer) {
  padding: 0.5rem;
}
:deep(.modal-header) {
  padding: 1rem;
}
</style>
