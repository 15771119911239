<template>
  <b-modal
    ref="modalStatusAendern"
    id="modalStatusAendern"
    size="md"
    :title="'Status'"
    centered
    @show="onShow"
    @hide="onHide"
    class="m-0 p-0"
  >
    <div class="d-flex w-full" style="gap: 8px">
      <b-button
        style="flex-grow: 1"
        @click="statusSelected = option.value"
        v-for="option in statusOptions"
        :key="option.text"
        :variant="statusSelected === option.value ? 'primary' : option?.variant ?? ''"
      >
        {{ option.text }}
      </b-button>
    </div>
    <b-form-group label="Verworfen Grund" v-if="statusSelected === 'Verworfen'" class="mt-3">
      <b-form-select :options="grundOptions" v-model="grundSelected"></b-form-select>
    </b-form-group>
    <b-form-group label="Kommentar" v-if="statusSelected === 'Verworfen'">
      <b-form-input v-model="kommentar"></b-form-input>
    </b-form-group>
    <template #modal-footer="{ hide }" class="pl-1">
      <b-button
        class="mr-4"
        @click="handleSubmit(hide)"
        :disabled="isDisableSichernButton"
        :variant="isDisableSichernButton ? '' : 'primary'"
      >
        Speichern
      </b-button>
      <b-button @click="hide">Abbrechen</b-button>
    </template>
  </b-modal>
</template>

<script>
import { grundOptions } from '@/core/common/helpers/utils';

export default {
  props: {
    reiseterminState: { type: String, required: true },
  },
  data() {
    return {
      grundOptions,
      statusSelected: null,
      kommentar: null,
      grundSelected: null,
    };
  },
  computed: {
    statusOptions() {
      if (this.reiseterminState) {
        if (this.reiseterminState === 'Vorgeschlagen') {
          return [
            { text: 'CM angefragt', value: 'CmAngefragt' },
            { text: 'Verworfen', value: 'Verworfen' },
          ];
        }
        if (this.reiseterminState === 'CmAngefragt') {
          return [
            { text: 'CM bestätigt', value: 'CmBestaetigt' },
            { text: 'Verworfen', value: 'Verworfen' },
          ];
        }
        if (this.reiseterminState === 'Verworfen') {
          return [{ text: 'Vorgeschlagen', value: 'Vorgeschlagen' }];
        }
        if (this.reiseterminState === 'CmBestaetigt') {
          return [
            { text: 'Anlagebereit', value: 'Anlagebereit' },
            { text: 'Verworfen', value: 'Verworfen' },
          ];
        }
        if (this.reiseterminState === 'Anlagebereit') {
          return [{ text: 'Verworfen', value: 'Verworfen' }];
        }
      }
      return [];
    },
    isDisableSichernButton() {
      if (this.statusSelected === null) return true;
      if (this.statusSelected === 'Verworfen' && this.grundSelected === null) return true;
      return false;
    },
  },
  methods: {
    onHide() {
      this.statusSelected = null;
      this.grundSelected = null;
      this.kommentar = null;
    },
    onShow() {},
    async handleSubmit(hide) {
      const confirmed = await this.$bvModal.msgBoxConfirm(
        `Möchtest du den Status wirklich in ${
          this.statusOptions.find(status => status.value === this.statusSelected)?.text
        } wechseln?`,
        {
          okTitle: 'OK',
          cancelTitle: 'Abbrechen',
          centered: true,
        }
      );
      if (confirmed) {
        this.$emit('statusAendern', {
          status: this.statusSelected,
          grund: this.grundSelected,
          kommentar: this.kommentar,
        });
        hide();
      }
    },
  },
};
</script>
