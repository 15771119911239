<template>
  <b-modal
    ref="reisetermin-anlegen-modal"
    id="modalReiseterminAnlegen"
    title="Reisetermin anlegen"
    class="modalReiseterminAnlegen"
    @show="onShow"
    @hide="onHide"
  >
    <b-overlay :show="isLoading">
      <div class="d-flex">
        <b-form-group class="w-50 mb-4" label="Reisekürzel" label-for="reisekuerzel">
          <Multiselect
            v-model="$v.form.reisekuerzel.$model"
            :options="reisenOptions"
            selectLabel=""
            selectGroupLabel=""
            deselectLabel=""
            deselectGroupLabel=""
            placeholder="Suchen..."
            selectedLabel="Ausgewählt"
            size="md"
            :style="{ borderColor: $v.form.reisekuerzel.$error ? '#E5EAEE' : '#1BC5BD' }"
            style="border-radius: 8px"
          ></Multiselect>
        </b-form-group>
        <b-form-checkbox size="sm" class="ml-auto" v-model="isSerie" button button-variant="primary">
          {{ isSerie ? 'Termin anlegen' : 'Serie anlegen' }}
        </b-form-checkbox>
      </div>
      <div class="d-flex mb-4" style="flex-direction: row; gap: 6px; flex-wrap: wrap">
        <b-button
          v-if="!isSerie && isTerminAlreadyExistantSoloTermine"
          :disabled="!isTerminAlreadyExistantSoloTermine"
          @click="removeAlreadyExistingDatesSolo"
          variant="danger"
          size="sm"
        >
          Entferne existierende Daten
        </b-button>
        <b-dropdown v-if="form.reisekuerzel && !isSerie" size="sm" text="Termine ins nächste Jahr kopieren">
          <b-dropdown-item
            @click="loadReiseterminStartdatenToNextYear(2024)"
            id="loadReiseterminStartdatenToNextYear2024"
          >
            2024
          </b-dropdown-item>
          <b-dropdown-item
            @click="loadReiseterminStartdatenToNextYear(2025)"
            id="loadReiseterminStartdatenToNextYear2025"
          >
            2025
          </b-dropdown-item>
          <b-dropdown-item
            @click="loadReiseterminStartdatenToNextYear(2026)"
            id="loadReiseterminStartdatenToNextYear2026"
          >
            2026
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <b-form-group v-if="!isSerie" class="w-100" label="Abreisedatum" label-for="date-picker">
        <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 12px">
          <div
            v-for="(abreisedatum, i) in abreisedaten"
            :key="'abreisedatum-' + i"
            class="d-flex"
            style="gap: 8px"
          >
            <DatePicker
              :lang="langConfig"
              v-model="abreisedaten[i].value"
              :disabledDate="disabledBeforeToday"
              :default-value="lastAbreisedatum"
              placeholder="Wähle ein Datum..."
              format="DD.MM.YYYY"
              class="w-100"
              :class="
                existingDates.some(item => areDatesEqual(item, abreisedatum.value))
                  ? 'existingReisetermin'
                  : isDatumVerworfen(abreisedatum.value)
                  ? 'verworfenerReisetermin'
                  : ''
              "
            ></DatePicker>
            <i
              v-if="i > 0"
              class="fas fa-times text-danger align-self-center"
              @click="removeAbreisedatum(abreisedatum.id)"
              style="cursor: pointer"
            ></i>
          </div>
        </div>
        <div class="d-flex mt-4">
          <div class="bg-primary plus-sign" @click="addAbreisedatum">+</div>
          <div class="ml-auto" v-if="form.reisekuerzel && abreisedaten.length > 0">
            {{ abreisedaten?.filter(item => item.value).length }} Reisetermine (Gesamt:
            {{ (existingDates?.length ?? 0) + abreisedaten.length }})
          </div>
        </div>
      </b-form-group>
      <div v-if="isSerie">
        <div class="d-flex" style="gap: 8px">
          <b-form-group class="w-50" label="Beginn" label-for="beginn">
            <DatePicker
              :lang="langConfig"
              v-model="$v.form.beginnSerie.$model"
              :disabledDate="disabledBeforeToday"
              placeholder="Wähle ein Startdatum..."
              format="DD.MM.YYYY"
              class="w-100"
            ></DatePicker>
          </b-form-group>
          <b-form-group class="w-50" label="Ende" label-for="ende">
            <DatePicker
              :lang="langConfig"
              v-model="$v.form.endeSerie.$model"
              :disabledDate="disabledBeforeToday"
              :default-value="form.beginnSerie ?? new Date()"
              placeholder="Wähle ein Enddatum..."
              format="DD.MM.YYYY"
              class="w-100"
            ></DatePicker>
          </b-form-group>
        </div>

        <b-form-group class="mb-4">
          <div class="d-flex">
            <div>Serienmuster:</div>
          </div>
          <div class="p-4" style="background-color: #e0e0e0; border-radius: 12px">
            <span class="d-flex align-items-center">
              Jede/Alle
              <b-form-input
                v-model="everyXWeek"
                style="width: 40px; height: 24px"
                class="ml-2 mr-2"
              ></b-form-input>
              Woche(n) am:
            </span>
            <b-form-checkbox-group
              v-model="selectedDays"
              :options="dayOptions"
              value-field="item"
              text-field="name"
              class="mt-2"
              style="display: grid; grid-template-columns: repeat(4, 1fr); gap: 12px"
            ></b-form-checkbox-group>
          </div>
        </b-form-group>
        <div class="my-3" v-if="form.reisekuerzel && formatDatesInInterval?.length > 0">
          {{ formatDatesInInterval?.length }} Reisetermine (Gesamt:
          {{ (existingDates?.length ?? 0) + (formatDatesInInterval?.length ?? 0) }})
        </div>
      </div>
      <b-button
        v-if="isSerie && isTerminAlreadyExistantInSerie"
        :disabled="!isTerminAlreadyExistantInSerie"
        @click="removeAlreadyExistingDatesSerie"
        variant="danger"
        size="sm"
        class="mb-4"
      >
        Entferne existierende Daten
      </b-button>
      <div
        v-if="isSerie && formatDatesInInterval.length > 0"
        style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 6px"
        class="mb-4"
      >
        <div
          v-for="date in formatDatesInInterval"
          :key="date"
          class="d-flex"
          :class="isFormattedDateInExistingDates(date) ? 'existingReisetermin' : 'bgLightGray'"
        >
          <span :id="date" class="p-1">
            {{ date }}
          </span>

          <i
            class="fas fa-times text-danger align-self-center ml-auto mr-2"
            @click="removeDateFromInterval(date)"
            style="cursor: pointer"
          ></i>
          <b-tooltip
            :target="date"
            triggers="hover"
            custom-class="custom-tooltip"
            v-if="isFormattedDateInExistingDates(date)"
          >
            Reisetermin existiert bereits!
          </b-tooltip>
        </div>
      </div>
    </b-overlay>

    <template #modal-footer="{ hide }" class="pl-1">
      <b-button
        class="mr-4"
        size="sm"
        :disabled="disableSichernButton"
        :variant="disableSichernButton ? 'secondary' : 'success'"
        @click="reiseterminAnlegen(hide)"
      >
        Erstellen
      </b-button>
      <b-button @click="hide" size="sm">Abbrechen</b-button>
    </template>
  </b-modal>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { validationMixin } from 'vuelidate';
import Multiselect from 'vue-multiselect';
import { required } from 'vuelidate/lib/validators';
import { checkDateInFuture, exactlySixChars, noDigits } from '@/core/common/helpers/utils.js';
import apiService from '@/core/common/services/api.service';
import { mapState } from 'vuex';
import buildQuery from 'odata-query';

import {
  addDays,
  getDay,
  isAfter,
  getWeek,
  format,
  parse,
  compareAsc,
  setDay,
  setYear,
  getYear,
  startOfYear,
  isEqual,
  endOfYear,
  isSameDay,
} from 'date-fns';
import { langConfig } from '@/core/common/helpers/utils.js';

export default {
  mixins: [validationMixin],
  name: 'ReiseterminAnlegenModal',
  components: { Multiselect, DatePicker },
  watch: {
    'form.beginnSerie': 'setDatesInInterval',
    'form.endeSerie': 'setDatesInInterval',
    everyXWeek: 'setDatesInInterval',
    selectedDays: 'setDatesInInterval',
    'form.reisekuerzel': 'loadReisekuerzelInformation',
    isSerie() {
      this.abreisedaten = [{ id: crypto.randomUUID(), value: null }];
      this.form.beginnSerie = null;
      this.form.endeSerie = null;
    },
  },
  data() {
    return {
      langConfig,
      isLoading: false,
      isSerie: false,
      everyXWeek: 1,
      selectedDays: [],
      dayOptions: [
        { item: 1, name: 'Montag' },
        { item: 2, name: 'Dienstag' },
        { item: 3, name: 'Mittwoch' },
        { item: 4, name: 'Donnerstag' },
        { item: 5, name: 'Freitag' },
        { item: 6, name: 'Samstag' },
        { item: 0, name: 'Sonntag' },
      ],
      dayFormatter: {
        Mo: 'Mo',
        Tu: 'Di',
        Di: 'Tu',
        Mi: 'We',
        Do: 'Th',
        So: 'Su',
        We: 'Mi',
        Th: 'Do',
        Fr: 'Fr',
        Sa: 'Sa',
        Su: 'So',
      },
      form: {
        reisekuerzel: null,
        beginnSerie: null,
        endeSerie: null,
      },
      datesInInterval: [],
      existingDates: [],
      verworfeneDates: {},
      abreisedaten: [{ id: crypto.randomUUID(), value: null }],
    };
  },
  validations: {
    form: {
      reisekuerzel: {
        required,
        exactlySixChars,
        noDigits,
      },
      beginnSerie: {
        ifChecked(v) {
          return this.isSerie && required(v);
        },
      },
      endeSerie: {
        ifChecked(v) {
          return this.isSerie && required(v);
        },
      },
    },
  },
  computed: {
    ...mapState({
      reisekuerzelOptions: state => state.reisen.reisenkuerzelFilterOptions,
    }),
    isTerminAlreadyExistantInSerie() {
      return this.existingDates.some(listDate =>
        this.datesInInterval.some(date => this.areDatesEqual(date, listDate, false))
      );
    },
    isTerminAlreadyExistantSoloTermine() {
      return this.existingDates.some(existingDate =>
        this.abreisedaten
          .filter(item => item.value != null)
          .some(date => this.areDatesEqual(date.value, existingDate, false))
      );
    },
    existingDatesTimes() {
      return this.existingDates.map(date => format(date, 'yyyy-MM-dd'));
    },
    reisenOptions() {
      if (this.reisekuerzelOptions.length > 0) {
        return this.reisekuerzelOptions
          .map(reise => reise.reisekuerzel)
          .slice()
          .sort();
      }
      return [];
    },
    beginnSerie() {
      if (this.form.beginnSerie) {
        return this.form.beginnSerie;
      } else return null;
    },
    endeSerie() {
      if (this.form.endeSerie) {
        return this.form.endeSerie;
      } else return null;
    },
    minFaelligkeitsdatum() {
      return new Date();
    },
    disableSichernButton() {
      return (
        this.isLoading ||
        !this.form.reisekuerzel ||
        (!this.isSerie && this.abreisedaten.some(item => item.value == null)) ||
        this.isTerminAlreadyExistantSoloTermine ||
        (this.isSerie && this.formatDatesInInterval.length === 0) ||
        this.isTerminAlreadyExistantInSerie
      );
    },
    getDatesInInterval() {
      if (this.everyXWeek > 0 && this.beginnSerie && this.endeSerie && this.isSerie) {
        let result = [];
        // get all days that are selected in the period
        let iteratedDate = this.beginnSerie;
        while (!isAfter(iteratedDate, this.endeSerie)) {
          if (this.selectedDays.includes(getDay(iteratedDate))) {
            result.push(iteratedDate);
          }
          iteratedDate = addDays(iteratedDate, 1);
        }
        const weekOfSeriesStart = getWeek(this.beginnSerie, { weekStartsOn: 1 });

        // filter result by every x week
        result = result.filter(date => {
          const weekOfDate = getWeek(date, { weekStartsOn: 1 });
          const isWeekInPeriod = (weekOfDate - weekOfSeriesStart) % this.everyXWeek === 0;
          if (isWeekInPeriod) {
            return true;
          } else return false;
        });
        return result;
      } else return [];
    },
    formatDatesInInterval() {
      if (this.getDatesInInterval?.length > 0) {
        return this.datesInInterval.map(date => {
          const formattedDate = format(date, 'EEEEEE., dd.MM.yyyy');
          const germanWeekDays =
            this.dayFormatter[formattedDate.substring(0, 2)] + formattedDate.substring(2);
          return germanWeekDays;
        });
      } else return [];
    },
    lastAbreisedatum() {
      const abreisedatumFiltered = this.abreisedaten.filter(item => item.value);

      if (abreisedatumFiltered.length == 0) return new Date();
      return abreisedatumFiltered.at(-1).value;
    },
  },
  methods: {
    translateDateToNextYear(date) {
      // Get the current year
      const nextYear = getYear(new Date(date)) + 1;

      // Set the date to the current year
      let translatedDate = setYear(date, nextYear);

      // Ensure the weekday matches the original date
      translatedDate = setDay(translatedDate, getDay(date), { weekStartsOn: 1 });

      return translatedDate;
    },
    async loadReiseterminStartdatenToNextYear(year) {
      if (this.form.reisekuerzel) {
        const firstDate = startOfYear(new Date(+year, 0, 1));
        const lastDate = endOfYear(new Date(+year, 0, 1));

        const filter = {
          abreisedatum: { ge: firstDate, le: lastDate },
          'reise/reisekuerzel': { eq: this.form.reisekuerzel },
          isDeleted: false,
        };
        const select = ['abreisedatum', 'state'];
        const orderBy = ['abreisedatum'];

        const query = buildQuery({
          filter,
          select,
          orderBy,
        });

        this.isLoading = true;

        try {
          await apiService.get('Reisetermin' + query).then(res => {
            const { value } = res.data.result;
            if (value.length > 0) {
              this.abreisedaten = value
                .map(item => ({
                  id: crypto.randomUUID(),
                  value: this.translateDateToNextYear(new Date(item.abreisedatum)),
                }))
                .sort((a, b) => compareAsc(new Date(a.value), new Date(b.value)));

              this.verworfeneDates = value.reduce((acc, curr) => {
                const datumNextYear = this.translateDateToNextYear(new Date(curr.abreisedatum));
                const formattedDateNextYear = format(datumNextYear, 'yyyy-MM-dd');
                acc[formattedDateNextYear] = curr.state === 'Verworfen';
                return acc;
              }, {});
            }
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
    areDatesEqual(date1, date2, considerTime = false) {
      if (considerTime) {
        return isEqual(date1, date2);
      } else {
        return isSameDay(date1, date2);
      }
    },
    async loadReisekuerzelInformation(reisekuerzel) {
      this.existingDates = [];
      this.verworfeneDates = {};
      this.abreisedaten = [{ id: crypto.randomUUID(), value: null }];
      this.isLoading = true;

      const filter = {
        abreisedatum: { ge: new Date() },
        'reise/reisekuerzel': reisekuerzel,
      };

      const select = ['abreisedatum'];
      const query = buildQuery({ filter, select });
      try {
        await apiService.get('Reisetermin' + query).then(res => {
          const { value } = res.data.result;

          this.existingDates = value
            .map(rt => new Date(rt.abreisedatum))
            .sort((a, b) => compareAsc(new Date(a), new Date(b)));
        });
      } finally {
        this.isLoading = false;
      }
    },
    removeAlreadyExistingDatesSerie() {
      this.datesInInterval = this.datesInInterval.filter(
        date => !this.existingDates.some(existingDate => this.areDatesEqual(existingDate, date, false))
      );
    },
    removeAlreadyExistingDatesSolo() {
      const allDates = this.abreisedaten.filter(item => item.value != null).map(item => item.value);
      const datesNotExisting = allDates
        .filter(item => !this.existingDatesTimes.includes(format(new Date(item), 'yyyy-MM-dd')))
        .sort((a, b) => compareAsc(new Date(a), new Date(b)));

      if (datesNotExisting.length > 0) {
        this.abreisedaten = datesNotExisting.map(item => ({
          id: crypto.randomUUID(),
          value: item,
        }));
      } else {
        this.abreisedaten = [{ id: crypto.randomUUID(), value: null }];
      }
    },
    isListMissingOneElement(list1, list2) {
      if (Math.abs(list1.length - list2.length) !== 1) return false;
      const [shorter, longer] = list1.length < list2.length ? [list1, list2] : [list2, list1];
      return longer.filter(item => !shorter.includes(item)).length === 1;
    },
    setDatesInInterval() {
      this.datesInInterval = this.getDatesInInterval;
    },
    addAbreisedatum() {
      this.abreisedaten.push({
        id: crypto.randomUUID(),
        value: null,
      });
    },
    removeAbreisedatum(id) {
      this.abreisedaten = this.abreisedaten.filter(item => item.id != id);
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    removeDateFromInterval(dateToDelete) {
      const formattedDateToDelete = new Date(parse(dateToDelete.substring(5), 'dd.MM.yyyy', new Date()));
      this.datesInInterval = this.datesInInterval.filter(
        date => date.getTime() != formattedDateToDelete.getTime()
      );
    },
    async reiseterminAnlegen(hide) {
      this.isLoading = true;
      const params = {
        reisekuerzel: this.form.reisekuerzel,
      };
      if (this.isSerie) {
        if (this.datesInInterval.length === 0) {
          this.$bvToast.toast('Falsche Einstellungen der Serie, kein Datum gefunden.', {
            title: 'Error',
            variant: 'danger',
          });

          return;
        }
        params.abreisedatum = this.datesInInterval.map(date => format(date, 'yyyy-MM-dd'));
      } else {
        if (this.abreisedaten.length > 0) {
          params.abreisedatum = [...this.abreisedaten.map(item => format(item.value, 'yyyy-MM-dd'))];
        }
      }
      apiService
        .post('Reisetermin', params)
        .then(() => {
          this.toast('Erfolgreich erstellt.');
          this.isLoading = false;
          hide();
          this.$emit('reiseterminAnlegen');
        })
        .catch(() => {
          this.isLoading = false;
          hide();
        });
    },
    onShow() {
      this.$v.form.$touch();
    },
    onHide() {
      this.form = {
        reisekuerzel: null,
        beginnSerie: null,
        endeSerie: null,
      };
      this.abreisedaten = [{ id: crypto.randomUUID(), value: null }];
      this.isSerie = false;
      this.everyXWeek = 1;
      this.selectedDays = [];
      this.verworfeneDates = {};
      this.existingDates = [];
    },
    isFormattedDateInExistingDates(formattedDate) {
      const revertGermanWeekDays =
        this.dayFormatter[formattedDate.substring(0, 2)] + formattedDate.substring(2);
      const date = parse(revertGermanWeekDays, 'EEEEEE., dd.MM.yyyy', new Date());
      return this.existingDates.some(listDate => this.areDatesEqual(date, listDate, false));
    },
    isDatumVerworfen(abreisedatum) {
      if (!abreisedatum) return false;
      const formattedDate = format(abreisedatum, 'yyyy-MM-dd');
      if (!Object.keys(this.verworfeneDates).includes(formattedDate)) return false;
      console.log(this.verworfeneDates[formattedDate]);
      return this.verworfeneDates[formattedDate];
    },
  },
};
</script>

<style scoped>
.centerModal {
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep(.multiselect__tags) {
  min-height: 36px;
  border-radius: 8px;
  background-color: var(--background-color);
}
:deep(.multiselect__single) {
  font-size: var(--font-size);
  background-color: var(--background-color);
  opacity: 1;
}
:deep(.multiselect__select) {
  height: 36px;
  border-radius: 8px;
  margin-top: 2px;
}
.multiselect {
  border: 1px solid var(--border-color);
  background-color: var(--background-color) !important;
  min-height: 36px;
}
:deep(.mx-input-wrapper > input) {
  font-size: 1rem;
  border: 1px solid #e8e8e8;
  height: 36px;
}

:deep(.existingReisetermin > .mx-input-wrapper > input) {
  background-color: #f64e6025;
}

:deep(.verworfenerReisetermin > .mx-input-wrapper > input) {
  background-color: #ffa80050;
}

:deep(.mx-input-wrapper > input) :deep(input::placeholder) {
  color: #adadad;
}
.plus-sign {
  height: 24px;
  width: 24px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

div.existingReisetermin {
  background-color: #f64e6075;
  border-radius: 4px;
}

.bgLightGray {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
</style>
